/* istanbul ignore file */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { enableFeatureInApp } from 'yoda-site-components/lib/helpers/Utils/features';
import { getDefaultOffSet } from 'yoda-site-components/lib/helpers/Utils/scrollPosition';

const useGalleryProductCardEffect = (
    findMobileOS,
    isDesktopWidth,
    skuId,
    cardRefDesktop,
    getDefaultOffSetValue
) => {
    let timerID = null;
    const state = useSelector((reduxState) => reduxState);
    const { context: { preferences: { iOSScrollTimeoutForBlankPage = 100 } = {} } = {} } = state;

    const removeLocalStorageData = (key, withKey, domain) => {
        try {
            LocalStorage.removeData(key, withKey, domain);
        } catch (error) {
            console.error(`Failed to remove data in LocalStorage: ${error}`);
        }
    };

    const handleReload = (event) => {
        if (event.persisted) {
            window.location.reload();
        }
    };

    const handleBackFromApps = () => {
        try {
            const nativeGalleryScrollToVal = LocalStorage.getData(
                'nativeGalleryScrollToOffSet',
                true
            );
            const parsedJson = nativeGalleryScrollToVal && JSON.parse(nativeGalleryScrollToVal);
            const galleryScrollToVal =
                parsedJson && parsedJson.val && parsedJson.key === window.location.href
                    ? parsedJson.val
                    : {};
            if (Object.keys(galleryScrollToVal).length !== 0) {
                const { galleryScrollTo, galleryScrollToOffSet } = galleryScrollToVal;
                if (galleryScrollTo === skuId) {
                    document.documentElement.scrollTop = galleryScrollToOffSet;
                    return;
                }
            } else {
                document.documentElement.scrollTop = 0;
            }
            timerID = setInterval(() => {
                window.scroll();
            }, iOSScrollTimeoutForBlankPage);
        } catch (e) {
            console.error('Native ScrollToProduct Error', e);
        }
    };

    const handleBackFromWebView = (galleryScrollTo, galleryScrollToOffSet) => {
        const { context: { featureFlags: { pdpBreadCrumbzScrollTop = true } = {} } = {} } = state;
        const pdpBreadCrumbsClickData = LocalStorage.getData('pdpBreadCrumbsClick');
        try {
            if (pdpBreadCrumbzScrollTop && pdpBreadCrumbsClickData) {
                document.documentElement.scrollTop = 0;
            } else if (isDesktopWidth) {
                cardRefDesktop?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            } else {
                document.documentElement.scrollTop =
                    galleryScrollTo === skuId ? galleryScrollToOffSet : getDefaultOffSetValue();
                /* YODATOF-917 - on IOS 13.6.0 greater version few times when coming back from PDP
                    page is going blank here dom is creating but not painting .. following code been added to fix this
                */
                if (findMobileOS === 'iOS' && !__SERVER__) {
                    timerID = setInterval(() => {
                        window.scroll();
                    }, iOSScrollTimeoutForBlankPage);
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('ScrollToProduct Error', e);
        }
        removeLocalStorageData('galleryFavoriteScrollTo', true, '');
        removeLocalStorageData('galleryScrollTo');
        removeLocalStorageData('galleryScrollToOffSet');
        removeLocalStorageData('pdpBreadCrumbsClick');
    };

    useEffect(() => {
        const {
            context: {
                isNative = false,
                preferences: { iosMinVersionGalleryReload = '11.4.0' } = {},
            } = {},
        } = state;
        const storedScrollTo = LocalStorage.getData('galleryScrollTo');
        const isNativeIOS = !__SERVER__ && isNative && findMobileOS === 'iOS';
        const iosMinVersion = { iosMinVersion: iosMinVersionGalleryReload };
        const enablefeature = enableFeatureInApp(iosMinVersion);
        if (!isNative || enablefeature) {
            window.addEventListener('pageshow', handleReload);
        }
        // this will be assigned when user clicked on favoriteicon on producttile
        const { galleryScrollTo, galleryScrollToOffSet } = getDefaultOffSet(
            'galleryFavoriteScrollTo'
        );

        if (!isNativeIOS && (storedScrollTo === skuId || galleryScrollTo === skuId)) {
            handleBackFromWebView(galleryScrollTo, galleryScrollToOffSet);
        } else if (isNativeIOS) {
            handleBackFromApps();
        }

        return () => {
            if (!isNative || enablefeature) {
                window.removeEventListener('pageshow', handleReload);
            }
            if (timerID) {
                clearInterval(timerID);
            }
        };
    }, []);
};

export default useGalleryProductCardEffect;
